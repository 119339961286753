// src/components/Header.js
import React, { useState } from 'react';
import './Header.css'; // Make sure the CSS file is properly linked
import logoImage from '../assets/images/stemplus_logo.png'; // Import the logo image
import { Link, useLocation } from 'react-router-dom'; // Use Link for navigation

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation(); // Get the current location

  // Toggle the menu state
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Close menu when a link is clicked (for mobile)
  const handleLinkClick = () => {
    setMenuOpen(false);
  };

  return (
    <header className="header">
      {/* Make both the logo and text clickable */}
      <Link to="/" className="logo" onClick={handleLinkClick}>
        <img src={logoImage} alt="Logo" />
        <span></span> {/* Clickable text along with the logo */}
      </Link>

      {/* Navigation Links */}
      <nav className={`nav ${menuOpen ? 'open' : ''}`}>
        <Link to="/" className={location.pathname === '/' ? 'active' : ''} onClick={handleLinkClick}>
          Home
        </Link>
        <Link to="/about" className={location.pathname === '/about' ? 'active' : ''} onClick={handleLinkClick}>
          About
        {/*</Link>
        <Link to="/stories" className={location.pathname === '/stories' ? 'active' : ''} onClick={handleLinkClick}>
          STEM Stories */}
        </Link>
        <Link to="/contact" className={location.pathname === '/contact' ? 'active' : ''} onClick={handleLinkClick}>
          Contact Us
        </Link>
      </nav>

      {/* FAQ Button 
      <Link to="/faq" className="faq-button" onClick={handleLinkClick}>FAQ</Link> 
      */}
      {/* Hamburger icon for mobile */}
      <div className="hamburger" onClick={toggleMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </header>
  );
};

export default Header;
