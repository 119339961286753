// src/pages/HomePage.js
import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import './HomePage.css';
import heroImage from '../assets/images/hero.jpg'; 
import aiImage from '../assets/images/ai.jpg'; 
import partnershipLogo from '../assets/images/partner.png'; 
import careerImage from '../assets/images/career1.jpg'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faGlobe, faStar, faBrain } from '@fortawesome/free-solid-svg-icons'; 
import transformImage from '../assets/images/boy.jpg'; 
import igniteImage from '../assets/images/ignite.jpg'; 
import roanokeImage from '../assets/images/roanoke-center.jpg'; 
import vrImage from '../assets/images/vr-paper.jpg'; 
import partnerIcon1 from '../assets/images/p1.jpg'; 
import partnerIcon2 from '../assets/images/p2.jpg'; 
import womensDayImage from '../assets/images/womensday.png';
import WomensDayPDF from '../assets/documents/international-womens-day.pdf';
import VRVideo from '../assets/videos/vr-in-classroom.mp4';


// Animation Variants for Slide-In
const slideInVariants = {
  hidden: { opacity: 0, x: -100 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.8,
      ease: "easeOut"
    }
  }
};

const slideInRightVariants = {
  hidden: { opacity: 0, x: 100 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.8,
      ease: "easeOut"
    }
  }
};

const HomePage = () => {
  return (
    <div className="homepage">
      {/* First Section - Hero Section */}
      <motion.section 
        className="hero-section"
        initial="hidden"
        whileInView="visible"
        variants={slideInVariants}
        viewport={{ once: true, amount: 0.2 }}
      >
        <div className="hero-content">
          <h1>Embrace Artificial Intelligence</h1>
          <p>
            Commonwealth Learning Systems introduces you to AI & LLM data.
            "To develop a complete mind: Study the science of art, study the art of science.
            Learn how to see. Realize that everything connects to everything else."
            — Leonardo da Vinci
          </p>
          <div className="hero-buttons">
            <Link to="/about" className="btn btn-primary">Explore Now</Link>
            <Link to="/contact" className="btn btn-secondary">Get Started</Link>
          </div>
        </div>
        <div className="hero-image">
          <img src={heroImage} alt="AI and Learning Illustration" />
        </div>
      </motion.section>
      <motion.section
  className="commonwealth-section"
  initial="hidden"
  whileInView="visible"
  variants={slideInVariants}
  viewport={{ once: true, amount: 0.2 }}
>
  {/* Heading and Subtext */}
  <div className="commonwealth-header">
    <h2>Commonwealth Learning Systems</h2>
    <p>
      "Emerging technologies are meeting this historical moment in education, and CLS is here to bridge connections for real-world solutions." – Holly Gillcash, Online Learning Specialist.
    </p>
  </div>

  {/* Cards Section */}
  <div className="commonwealth-cards">
    {/* Roanoke Center Card */}
    <div className="commonwealth-card">
      <img src={roanokeImage} alt="Roanoke Center" />
      <div className="card-contentp">
        <h3>Discover</h3>
        <h4>Roanoke Higher Education Center</h4>
        <p>
          Refer pages 12 and 13 of this document to learn more about the impact of CLS in STEM Education.
        </p>
        <a
          href="https://www.education.edu/wp-content/uploads/2020/12/RHEC-ANNUAL-REPORT-11-23-20-web.pdf"
          className="btn btn-secondary"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn More
        </a>
      </div>
    </div>

    {/* International Women's Day Card */}
    <div className="commonwealth-card">
      <img src={womensDayImage} alt="International Women's Day" />
      <div className="card-contentp">
        <h3>Discover</h3>
        <h4>International Women's Day</h4>
        <p>
          Join us in honoring the achievements of women in STEM and exploring the path forward for gender equality in education.
        </p>
        <a
          href={WomensDayPDF}
          className="btn btn-secondary"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn More
        </a>
      </div>
    </div>

    {/* VR in Classroom Paper Card */}
    <div className="commonwealth-card">
      <img src={vrImage} alt="VR in classroom paper" />
      <div className="card-contentp">
        <h3>Discover</h3>
        <h4>VR in Classroom Paper</h4>
        <p>
          StemPlus is dedicated to creating a global network of STEM resources with cutting-edge educational tools.
        </p>
        <a
          href={VRVideo}
          className="btn btn-secondary"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn More
        </a>
      </div>
    </div>
  </div>
</motion.section>

      {/* Second Section - AI in STEM Education */}
      <motion.section
  className="partnership-section"
  initial="hidden"
  whileInView="visible"
  variants={slideInVariants}
  viewport={{ once: true, amount: 0.2 }}
>
  <a 
    href="https://questloft.up.railway.app/" 
    target="_blank" 
    rel="noopener noreferrer" 
    className="partnership-link"
  >
    <div className="partnership-card">
      <img 
        src={partnershipLogo} 
        alt="StemPlus X QuestLOFT" 
        className="partnership-logo" 
      />
      <div className="partnership-content">
        <h3>StemPlus X QuestLOFT</h3>
        <p>Empowering STEM</p>
      </div>
    </div>
  </a>
</motion.section>
<motion.section 
  className="transforming-stem-section"
  initial="hidden"
  whileInView="visible"
  variants={slideInVariants}
  viewport={{ once: true, amount: 0.2 }}
>
  <div className="transforming-content">
    <h2>Transforming STEM</h2>
    <p>
    At StemPlus, we believe in breaking barriers and creating opportunities for every learner. Through groundbreaking initiatives like our collaboration with NOVA, we enabled students to earn both their high school diplomas and associate degrees, fully covering all associated costs. Our commitment to fostering innovation doesn’t stop there.
    </p>
    <p>
    Supported by the ICAT Grant and driven by passionate graduate students, we contributed to the cutting-edge publication “Supporting Embodied and Remote Collaboration in Shared Virtual Environments” (2021 HCI International Conference). This work reflects our dedication to empowering students and educators with immersive, future-ready learning experiences.
    </p>
    <p>Be part of this transformation. Join us in redefining STEM education, where technology meets opportunity, and together, we’ll shape the future of learning.</p>
    <div className="transforming-buttons">
      <a href="https://dl.acm.org/doi/abs/10.1007/978-3-030-77599-5_44" className="btn btn-primary">Learn More</a>
      <Link to="/contact" className="btn btn-secondary">Join Us</Link>
    </div>
  </div>
  <motion.div 
    className="transforming-image"
    initial="hidden"
    whileInView="visible"
    variants={slideInRightVariants}
    viewport={{ once: true, amount: 0.2 }}
  >
    <img src={transformImage} alt="Transforming STEM" />
  </motion.div>
</motion.section>
      <motion.section 
        className="ai-education-section"
        initial="hidden"
        whileInView="visible"
        variants={slideInVariants}
        viewport={{ once: true, amount: 0.2 }}
      >
        <div className="ai-content">
          <h2>Unlock the Future of STEM Education With AI</h2>
          <p>
            At StemPlus, we believe that STEM education is the key to unlocking a world of boundless possibilities.
            Our mission is to empower individuals of all ages to explore the wonders of science, technology, engineering, and mathematics.
          </p>
          <Link to="/about" className="btn btn-primary">Learn More</Link>
        </div>
        <motion.div 
          className="ai-image"
          initial="hidden"
          whileInView="visible"
          variants={slideInRightVariants}
          viewport={{ once: true, amount: 0.2 }}
        >
          <img src={aiImage} alt="STEM Education with AI" />
        </motion.div>
      </motion.section>

      {/* Third Section - Partnership Section */}
      {/* Third Section - Partnership Section */}


      {/* Fourth Section - Career Pathways */}
      <motion.section 
        className="career-pathways-section"
        initial="hidden"
        whileInView="visible"
        variants={slideInVariants}
        viewport={{ once: true, amount: 0.2 }}
      >
        <div className="career-content">
          <h2>Career Pathways</h2>
          <p>
            Unlock your potential with StemPlus, a community-driven platform dedicated to making STEM education accessible and engaging for learners of all ages.
          </p>
          <div className="career-buttons">
            <Link to="/contact" className="btn btn-primary">Explore Now</Link>
            {/*<a href="/get-started-careers" className="btn btn-secondary">Get Started</a>*/}
          </div>
        </div>
        <motion.div 
          className="career-image"
          initial="hidden"
          whileInView="visible"
          variants={slideInRightVariants}
          viewport={{ once: true, amount: 0.2 }}
        >
          <img src={careerImage} alt="Career Pathways Image" />
        </motion.div>
      </motion.section>

      {/* Fifth Section - Icon Cards */}
      
      

      <motion.section
        className="ignite-section"
        initial="hidden"
        whileInView="visible"
        variants={slideInVariants}
        viewport={{ once: true, amount: 0.2 }}
      >
        <div className="ignite-content">
          <h2>Ignite Your STEM Journey</h2>
          <p>
            Discover a vibrant community of STEM enthusiasts at StemPlus,
            where learners of all ages can explore interactive lessons.
          </p>
          <div className="ignite-buttons">
            <Link to="/contact" className="btn btn-primary">Explore Now</Link>
          </div>
        </div>
        <motion.div 
          className="ignite-image"
          initial="hidden"
          whileInView="visible"
          variants={slideInRightVariants}
          viewport={{ once: true, amount: 0.2 }}
        >
          <img src={igniteImage} alt="STEM Journey" />
        </motion.div>
      </motion.section>
      <motion.section
        className="icon-cards-section"
        initial="hidden"
        whileInView="visible"
        variants={slideInVariants}
        viewport={{ once: true, amount: 0.2 }}
      >
        <div className="icon-card">
          <FontAwesomeIcon icon={faGlobe} size="2x" />
          <p>Reach Global</p>
        </div>
        <div className="icon-card">
          <FontAwesomeIcon icon={faStar} size="2x" />
          <p>Inspire</p>
        </div>
        <div className="icon-card">
          <FontAwesomeIcon icon={faBrain} size="2x" />
          <p>Empower STEM</p>
        </div>
      </motion.section>
    {/* New Section - Commonwealth Learning Systems */}
    
      <motion.section
  className="partners-section"
  initial="hidden"
  whileInView="visible"
  variants={slideInVariants}
  viewport={{ once: true, amount: 0.2 }}
>
  <div className="partners-header">
    <h2>Our Partners</h2>
    <p>
      StemPlus is proud to collaborate with a network of industry-leading partners who share our vision of making STEM education accessible and engaging for learners of all ages.
    </p>
  </div>

  <div className="partners-cards">
    {/* Virginia Tech Foundation Card */}
    <div className="partner-card" style={{ backgroundColor: '#FFB001' }}>
      <img src={partnerIcon1} alt="Virginia Tech Foundation" />
      <div className="card-content">
        <h3>Virginia Tech Foundation</h3>
        <p>Discover the wealth of STEM resources and collaborative opportunities available through our programs.</p>
        <a href="https://www.vtf.org/" target="_blank" rel="noopener noreferrer" className="btn btn-light">
          Get Started
        </a>
      </div>
    </div>

    {/* Qualcomm Lab Card */}
    <div className="partner-card" style={{ backgroundColor: '#EB6659' }}>
      <img src={partnerIcon2} alt="Qualcomm Lab" />
      <div className="card-content">
        <h3>Qualcomm Lab</h3>
        <p>Explore our diverse range of STEM resources and collaborative tools designed for innovative education.</p>
        <a href="https://thinkabitlab.com/" target="_blank" rel="noopener noreferrer" className="btn btn-light">
          Join Now
        </a>
      </div>
    </div>
  </div>
</motion.section>

    </div>
  );
};

export default HomePage;