// src/pages/AboutPage.js
import React from 'react';
import { motion } from 'framer-motion'; // Import Framer Motion for animations
import './AboutPage.css'; // Import the CSS file for styling

const AboutPage = () => {
  return (
    <div className="about-page">
      <motion.main
        className="about-container"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        {/* Animate headers and content */}
        <motion.h1
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
        >
          Who are StemPlus?
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, delay: 0.2 }}
        >
          StemPlus is a network of educators, specialists, and community-focused organizations
          developing educational opportunities that work in our rapidly changing educational landscape.
          Our particular focus is on STEM+ fields and the skill sets needed in our rapidly changing workforce.
        </motion.p>
        <motion.p
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, delay: 0.4 }}
        >
          Our network includes designers, instructors, and business partners from across the Commonwealth
          in institutions serving K-12 to adult learning.
        </motion.p>

        <motion.h2
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, delay: 0.6 }}
        >
          STEM+ Online Learning
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, delay: 0.8 }}
        >
          StemPlus has focused on online learning and integrating emerging technologies in STEM education —
          survival strategies during the global pandemic. Not all online learning will go back into the box
          as students return to the classroom. Educators now know that some online educational activities
          can supplement traditional brick-and-mortar programs. In fact, online education can help "level
          the playing field" by linking communities to content experts to stay "in the loop" on emerging technologies.
        </motion.p>

        <motion.h2
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, delay: 1 }}
        >
          Contact Us
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, delay: 1.2 }}
        >
          Email: <a href="mailto:jmurph@stemplus.net">jmurph@stemplus.net</a>
        </motion.p>
      </motion.main>
    </div>
  );
};

export default AboutPage;
