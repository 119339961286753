// src/pages/StemStoriesPage.js
import React from 'react';
import { motion } from 'framer-motion';
import './StemStoriesPage.css';

const stories = [
  {
    title: 'Auto-Tune, Acid Loops, Varispeed, and...',
    author: 'Deanmcintyre',
    date: 'June 9, 2021',
    description: 'What is Auto-tune? You may have heard of the term "Auto-Tune" before...',
    image: 'https://via.placeholder.com/200'
  },
  {
    title: 'H. G. Wells – Game Developer',
    author: 'Deanmcintyre',
    date: 'April 8, 2021',
    description: 'H. G. WellsHerbert George Wells is considered to be one of the creators...',
    image: 'https://via.placeholder.com/200'
  },
  {
    title: 'Who Discovered Washing Hands Prevents...',
    author: 'Deanmcintyre',
    date: 'April 8, 2021',
    description: 'The idea of washing hands to prevent the spread of disease is not a new...',
    image: 'https://via.placeholder.com/200'
  },
  // Add more stories as needed
];

const StemStoriesPage = () => {
  console.log('StemStoriesPage rendered');
  return (
    <div className="stem-stories-page">
      <main className="stories-container">
        {stories.map((story, index) => (
          <motion.div
            className="story-card"
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <img src={story.image} alt={story.title} className="story-image" />
            <div className="story-content">
              <h3>{story.title}</h3>
              <p className="story-meta">
                <span className="author">{story.author}</span> • <span className="date">{story.date}</span>
              </p>
              <p className="story-description">{story.description}</p>
              <button className="read-more">Read More</button>
            </div>
          </motion.div>
        ))}
      </main>
    </div>
  );
};

export default StemStoriesPage;
