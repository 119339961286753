// src/pages/ContactPage.js
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import emailjs from 'emailjs-com'; // Import emailjs
import './ContactPage.css';

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus('Sending...');

    const serviceID = 'service_gtrwkpx'; // Replace with your EmailJS service ID
    const templateID = 'template_4yeuxgd'; // Replace with your EmailJS template ID
    const userID = '5Sgio73S-JcpGVobL'; // Replace with your EmailJS user ID (public key)

    // Set up the data to match the placeholders in the EmailJS template
    const templateParams = {
      from_name: formData.name,
      from_email: formData.email,
      subject: formData.subject,
      message: formData.message,
    };

    emailjs.send(serviceID, templateID, templateParams, userID)
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setStatus('Message sent successfully!');
        setFormData({ name: '', email: '', subject: '', message: '' }); // Clear form
      })
      .catch((error) => {
        console.error('FAILED...', error);
        setStatus('Failed to send message. Please try again.');
      });
  };

  return (
    <div className="contact-page">
      <motion.main
        className="contact-container"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <h1>Questions? Contact us</h1>
        <p>
          Submit the form below or email us at{' '}
          <a href="mailto:jmurph@stemplus.net">jmurph@stemplus.net</a>
        </p>
        <form className="contact-form" onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Your Name (required)"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Your Email (required)"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="subject"
            placeholder="Subject"
            value={formData.subject}
            onChange={handleChange}
          />
          <textarea
            name="message"
            placeholder="Your Message"
            rows="5"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
          <button type="submit" className="submit-button">
            Send
          </button>
        </form>
        <p>{status}</p>
      </motion.main>
    </div>
  );
};

export default ContactPage;
